(function ($) {
  function isHomepage() {
    if (window.location.pathname == '/') {
      return true;
    }

    return false;
  }

  function startTimer(bannerElement, timerElement, countDownDate) {
    var time,
      distance,
      days,
      hours,
      minutes,
      seconds;
    var hideOnHomepage = parseInt(bannerElement.attr('hide-on-homepage'));
    var hideEntireBanner = parseInt(bannerElement.attr('data-hide-banner'));
    var intervalHandler = setInterval(function () {
      distance = countDownDate - new Date().getTime();

      if ((distance < 0) || (hideOnHomepage && isHomepage()) || hideEntireBanner) {
        clearInterval(intervalHandler);
        bannerElement.hide();
        $('.block-template-hp-counter-v1').height(0);

        return;
      } else {
        bannerElement.show();
      }

      // add this class to increase the height of the header
      bannerElement.closest('.site-header').addClass('site-header__counter');

      time = '';
      days = Math.floor(distance / (1000 * 60 * 60 * 24));
      if (days > 0) {
        if (days < 10) {
          time += '0';
        }
        time += days + ':';
      }

      hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      if (days > 0 || hours > 0) {
        if (hours < 10) {
          time += '0';
        }
        time += hours + ':';
      }

      minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      if (days > 0 || hours > 0 || minutes > 0) {
        if (minutes < 10) {
          time += '0';
        }
        time += minutes + ':';
      }

      seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
        if (seconds < 10) {
          time += '0';
        }
        time += seconds;
      }

      timerElement.text(time);
    }, 1000);
  }

  Drupal.behaviors.hpCounter = {
    attach: function () {
      var element,
        dateString,
        countDownDate,
        menu;

      element = $('.hp-counter');
      dateString = element.attr('end-date');

      // parse the date
      if (dateString) {
        countDownDate = Date.parse(dateString);
      }

      if (countDownDate) {
        this.startTimer(element, element.find('.timer'), countDownDate);

        return;
      }

      element.hide();
      $('.block-template-hp-counter-v1').height(0);
    },
    isHomepage: isHomepage,
    startTimer: startTimer
  };
})(jQuery);
